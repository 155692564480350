.newUserForm label{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #3C4A5A;
}

.newUserForm{
    min-width: 350px;
    max-width: 440px;
}

.termsandpolicy{
    font-size: 13px;
}

.termsandpolicy a{
    font-weight: 600;
}

.newUserForm input::placeholder{
    font-size: 13px;
    color: #919191;
}

.signUpPageTitle{
    color: #3C4A5A;
    font-weight: 600;
}

.harbinger-logo-azul{
    background-image: url(../img/harbinger-logo-azul192.png);
    height: 100px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.color-login{
    color: #3C4A5A;
}

.price-font{
    color: #263c7a;
    font-weight: 550;
}

.loading-button{
    width: 1rem !important;
    height: 1rem !important;
}


.custom-google-button {
    display: inline-block;
    background-color: rgb(229, 224, 224);
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-google-button:hover {
    background-color: #e7eaef;
  }
  
  /* Optional: Add a Google logo */
  .custom-google-button:before {
    content: "";
    display: inline-block;
    background-image: url("../img/Googlepng.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 2
  }